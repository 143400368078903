import { logout as toLogout } from './utils/login'
import router from './router'
import axios from 'axios'
import { Toast } from 'vant'

export const BASE_URL = 'https://vip.midltd.vip'

const instance = axios.create({
  baseURL: BASE_URL
})

instance.interceptors.response.use(function(response) {
  const data = response.data
  // 登录过期
  if (data.code === 5001) {
    Toast(data.info)
    toLogout()
    router.replace({ name: 'login' })
  }
  if (data.code !== 1000) {
    Toast(data.info)
    return Promise.reject('')
  }
  return data
}, function(error) {
  Toast('系统错误:', error)
  return Promise.reject(error)
})

const formatQuery = data => {
  let params = []
  Object.keys(data).forEach(key => params.push(`${key}=${data[key]}`))
  return params.join('&')
}

export const getCategory = () => {
  return instance.get('/index.php?c=Api&f=getcategory')
}

export const getHotelList = (data = { page: 1, pagesize: 10 }) => {
  return instance.get(`/index.php?c=Api&f=lists&${formatQuery(data)}`)
}

export const getHotelInfo = (data) => {
  return instance.get(`/index.php?c=Api&f=gethotelinfo&${formatQuery(data)}`)
}

export const login = (data) => {
  return instance.get(`/index.php?m=Member&c=Api&${formatQuery(data)}`)
}

export const sendSms = (data) => {
  return instance.get(`/index.php?m=Bysms&c=Api&f=checkphone&${formatQuery(data)}`)
}

export const logout = (data) => {
  return instance.post(`/index.php?m=Member&c=Api&f=logout&${formatQuery(data)}`)
}

export const getBookList = (data) => {
  return instance.get(`/index.php?m=Orderform&c=Api&f=appointment&${formatQuery(data)}`)
}

export const getBookDetail = (data) => {
  return instance.get(`/index.php?m=Orderform&c=Api&f=appointment_info&${formatQuery(data)}`)
}

export const saveBookDetail = (data) => {
  return instance.get(`/index.php?m=Orderform&c=Api&f=appointment_save&${formatQuery(data)}`)
}

export const refuseOrder = (data) => {
  return instance.get(`/index.php?m=Orderform&c=Api&f=appointment_refund&${formatQuery(data)}`)
}

export const createOrder = (data) => {
  return instance.get(`/index.php?m=Orderform&c=Api&f=quickorder&category=0&${formatQuery(data)}`)
}

export const upWechatPay = (data) => {
  return instance.get(`/index.php?m=Orderform&c=Wxpay&f=dopay&${formatQuery(data)}`)
}

export const checkPayResult = (data) => {
  return instance.get(`/index.php?m=Orderform&c=Api&f=confirm&${formatQuery(data)}`)
}